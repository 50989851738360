import styled from "styled-components";

export default styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0 50px;
  .settings-content {
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .sidebar {
      width: 200px;
    }
    .settings-content {
      width: calc(100% - 200px);
      padding-left: 32px;
      padding-top: 12px;
    }
  }
`;
