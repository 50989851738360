import React from "react";
import PropTypes from "prop-types";

import SubNavBar from "Components/SubNavBar";

const getTabs = organizationId => [
  {
    name: "payment_details",
    route: `/${organizationId}/billing/details`
  },
  {
    name: "billing_address",
    route: `/${organizationId}/billing/details/address`
  },
  {
    name: "vouchers",
    route: `/${organizationId}/billing/details/vouchers`
  }
];

const OrganizationBillingSubNavBar = ({ organizationId, push, pathname }) => (
  <SubNavBar
    push={push}
    tabs={getTabs(organizationId)}
    currentPathName={pathname}
    layout="vertical"
  />
);

OrganizationBillingSubNavBar.propTypes = {
  push: PropTypes.func,
  pathname: PropTypes.string,
  organizationId: PropTypes.string
};

export default OrganizationBillingSubNavBar;
