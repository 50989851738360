import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import InputField from "./fields/InputField";
import Button from "./Button";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading3 from "Components/styleguide/Heading3";
import Dropdown from "Components/Dropdown";
import client from "Libs/platform";
import { connect } from "react-redux";
import countryList from "react-select-country-list";

const Layout = styled.form`
  padding: 32px;
  .select-box,
  .select-box__control,
  .select-box__value-container {
    height: 40px;
  }
  .select-box__control {
    min-height: 40px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .account-settings-wrapper {
    padding: 32px;
  }
  .input-wrapper {
    margin-bottom: 16px;
  }
  .request-password {
    margin-top: 16px;
    font-size: 14px;
    text-decoration: none;
    color: ${props => props.theme.links};
    display: inline-block;
  }
  hr {
    margin: 32px 0 24px;
  }
  .image-upload-field {
    margin-right: 32px;
  }
  h3 {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

const Column = styled.div`
  width: calc(50% - 16px);
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
  .field,
  .dropdown,
  .dropdown .input-wrapper,
  .dropdown .select-box {
    width: 295px;
  }
  .field-locality {
    width: 334px;
  }
`;

const InlineFields = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 334px;
  margin-bottom: 32px;
  .input-field {
    width: calc(43% - 30px);
    &:first-child {
      width: 57%;
      margin-right: 30px;
    }
  }
`;

class OrganizationBillingCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      address: {
        name_line: props.name,
        thoroughfare: "",
        premise: "",
        locality: "",
        administrative_area: "",
        postal_code: "",
        country: ""
      },
      addressProxy: {
        administrative_area: null,
        country: null,
        dependent_locality: null,
        locality: null,
        name_line: null,
        postal_code: null,
        premise: null,
        sub_administrative_area: null,
        sub_premise: null,
        thoroughfare: null
      }
    };

    this.changeField = this.changeField.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onThoroughfareChange = this.onThoroughfareChange.bind(this);
    this.onPremiseChange = this.onPremiseChange.bind(this);
    this.onLocalityChange = this.onLocalityChange.bind(this);
    this.onAdministrativeAreaChange = this.onAdministrativeAreaChange.bind(
      this
    );
    this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const address = await client.getAddress(this.props.userId);
    this.setState(prevState => ({
      ...prevState,
      address: {
        name_line: address.name_line,
        thoroughfare: address.thoroughfare,
        premise: address.premise,
        locality: address.locality,
        administrative_area: address.administrative_area,
        postal_code: address.postal_code,
        country: address.country
      },
      addressProxy: address
    }));
  }

  onNameChange(e) {
    const name_line = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        name_line
      },
      focused: "name_line"
    }));
  }

  onThoroughfareChange(e) {
    const thoroughfare = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        thoroughfare
      },
      focused: "thoroughfare"
    }));
  }

  onPremiseChange(e) {
    const premise = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        premise
      },
      focused: "premise"
    }));
  }

  onLocalityChange(e) {
    const locality = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        locality
      },
      focused: "locality"
    }));
  }

  onAdministrativeAreaChange(e) {
    const administrative_area = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        administrative_area
      },
      focused: "administrative_area"
    }));
  }

  onPostalCodeChange(e) {
    const postal_code = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        postal_code
      },
      focused: "postal_code"
    }));
  }

  onCountryChange({ value }) {
    const country = value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        country
      },
      focused: "country"
    }));
  }

  getNewAddressFields() {
    const { address, addressProxy } = this.state;
    let newFields = {};
    for (const p in address) {
      if (address[p] !== addressProxy[p]) {
        newFields[p] = address[p];
      }
    }
    return newFields;
  }
  changeField(e, field) {
    this.setState({
      [field]: e.target.value
    });
  }

  onSave() {
    const { addressProxy: address } = this.state;
    const { userId } = this.props;
    const newFields = this.getNewAddressFields();
    if (Object.keys(newFields).length > 0) {
      address.update(newFields, userId);
    }
  }

  async onCancel() {
    const address = await client.getAddress(this.props.userId);
    this.setState(prevState => ({
      ...prevState,
      address: {
        name_line: address.name_line,
        thoroughfare: address.thoroughfare,
        premise: address.premise,
        locality: address.locality,
        administrative_area: address.administrative_area,
        postal_code: address.postal_code,
        country: address.country
      },
      addressProxy: address
    }));
  }

  render() {
    const { intl } = this.props;
    const {
      address: {
        name_line,
        country,
        thoroughfare,
        premise,
        locality,
        administrative_area,
        postal_code
      }
    } = this.state;
    const defaultCountry = countryList()
      .getData()
      .find(i => i.value === country);

    return (
      <Layout>
        <ColumnWrapper>
          <Column>
            <Heading3>Addressee</Heading3>
            <InputField
              label="Full name"
              value={name_line}
              onChange={e => this.onNameChange(e)}
            />
            <InputField
              label="Company name"
              value={this.state.companyName}
              onChange={e => this.changeField(e, "companyName")}
              required={false}
            />
          </Column>
          <Column>
            <Heading3>Billing Address</Heading3>

            <Dropdown
              options={countryList().getData()}
              name="country"
              onChange={this.onCountryChange}
              searchable={false}
              value={defaultCountry}
              defaultValue={{ value: defaultCountry, label: defaultCountry }}
              clearable={false}
              label={intl.formatMessage({ id: "select_country" })}
              placeholder={intl.formatMessage({ id: "select_country" })}
              fieldType={true}
              required={true}
            />
            <InputField
              id="thoroughfare"
              name="thoroughfare"
              label={intl.formatMessage({ id: "address" })}
              placeholder={intl.formatMessage({ id: "address" })}
              className="address"
              value={thoroughfare}
              onChange={this.onThoroughfareChange}
              required={true}
            />
            <InputField
              id="premise"
              name="premise"
              label={intl.formatMessage({ id: "address2" })}
              placeholder={intl.formatMessage({ id: "address2" })}
              className="address"
              value={premise}
              onChange={this.onPremiseChange}
              required={false}
            />
            <InputField
              id="locality"
              name="locality"
              label={intl.formatMessage({ id: "city" })}
              placeholder={intl.formatMessage({ id: "city" })}
              className="locality"
              value={locality}
              onChange={this.onLocalityChange}
            />
            <InlineFields>
              <InputField
                id="administrative_area"
                name="administrative_area"
                label={intl.formatMessage({
                  id: "state_province_region"
                })}
                placeholder={intl.formatMessage({
                  id: "state_province_region"
                })}
                className="administrative_area"
                value={administrative_area}
                onChange={this.onAdministrativeAreaChange}
              />
              <InputField
                id="postal_code"
                name="postal_code"
                label={intl.formatMessage({ id: "zip_postal_code" })}
                placeholder={intl.formatMessage({
                  id: "zip_postal_code"
                })}
                className="postal_code"
                value={postal_code}
                onChange={this.onPostalCodeChange}
              />
            </InlineFields>
          </Column>
        </ColumnWrapper>
        <ButtonWrapper>
          <Button
            id="save_organization_general_settings_button"
            className="primary"
            type="submit"
            aria-label={intl.formatMessage({ id: "save_changes" })}
            onClick={e => {
              e.preventDefault();
              this.onSave();
            }}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </Button>
          <Button
            id="cancel-organization-name-btn"
            className="secondary"
            type="button"
            aria-label={intl.formatMessage({ id: "cancel" })}
            onClick={this.onCancel}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </ButtonWrapper>
      </Layout>
    );
  }
}

OrganizationBillingCardForm.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.object,
  intl: PropTypes.object,
  name: PropTypes.string,
  userId: PropTypes.string
};

const mapStateToProps = state => ({
  userId: state.app && state.app.getIn(["me", "id"])
});

export default connect(mapStateToProps)(
  injectIntl(OrganizationBillingCardForm)
);
