import React from "react";
import PropTypes from "prop-types";

import OrganizationBillingSubNavBar from "Components/OrganizationBillingSubNavBar";
import ContentLayout from "Components/ContentLayout";
import SettingsLayout from "Components/SettingsLayout";
import Heading2 from "Components/styleguide/Heading2";
import OrganizationBillingAddressForm from "Components/OrganizationBillingAddressForm";
import ListGroup from "Components/ListGroup";

class OrganizationBillingAddress extends React.Component {
  render() {
    const { location, router, params } = this.props;
    return (
      <SettingsLayout>
        <OrganizationBillingSubNavBar
          pathname={location.pathname}
          push={router.push}
          organizationId={params.organizationId}
        />
        <ContentLayout id="settings-members" className="settings-content">
          <Heading2 id="billing-address-heading">Billing Address</Heading2>
          <ListGroup aria-labelledby="billing-address-heading">
            <OrganizationBillingAddressForm />
          </ListGroup>
        </ContentLayout>
      </SettingsLayout>
    );
  }
}

OrganizationBillingAddress.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default OrganizationBillingAddress;
