import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Tabs from "./Tabs";

const Layout = styled.div`
  &.sidebar {
    background: transparent;
  }
`;

const SubNavBarLayout = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
  max-height: 30px;
  padding: 10px 0 20px;
  flex-shrink: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  &.subnav-wrapper {
    overflow: visible;
    padding: 23px 0 19px;
    box-sizing: border-box;
    height: 64px;
    min-height: 64px;
    a.button {
      margin-bottom: -50%;
      text-transform: none;
      letter-spacing: normal;
    }
    a:not(.button) {
      padding: 0;
      line-height: 22px;
      height: 22px;
      font-size: 13px;
      &:hover,
      &:focus,
      &:active {
        color: #0e0d34;
        outline: none;
      }
      &.active {
        font-weight: 600;
        position: relative;
        background: transparent;
        &:before {
          content: "";
          display: inline-block;
          width: 100%;
          position: absolute;
          bottom: -4px;
          left: 0;
          height: 2px;
          background: #32324c;
        }
      }
    }
    .subnavbar {
      overflow: visible;
      height: 22px;
      border-bottom: 1px solid #c9d0e4;
    }
  }
  &.tab-layout-vertical {
    min-height: auto;
    max-height: 100%;
    text-align: left;
    background: transparent;
    padding: 0;
    margin: 0;
    height: auto;
    .subnavbar {
      padding: 0;
      height: 100%;
    }
    .tabs {
      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
        padding: 0;
      }
    }
    .tab {
      text-align: left;
      line-height: 48px;
      padding: 0 24px;
      color: #000;
      width: 100%;
      display: block;
      box-sizing: border-box;
      height: auto;
      font-size: 16px;
      margin-bottom: 6px;
      border-radius: 4px;
      text-transform: capitalize;
      letter-spacing: 0;
      &.active,
      &:hover,
      &:active {
        background: #fff;
        box-shadow: 0 4px 8px rgba(152, 160, 171, 0.4);
      }
      &:focus {
        outline: none;
        border: 1px solid #1664ff;
        border-radius: 4px;
        box-shadow: 0 0 2px #1664ff;
        margin: -1px 0 5px -2px;
      }
      &.active,
      &:active {
        color: ${props => props.theme.links};
        border: none;
        margin: 0 0 6px 0;
        box-shadow: 0 4px 8px rgba(152, 160, 171, 0.4);
      }
      &.tab-access,
      &.tab-variables {
        padding-left: 25px;
      }
    }
  }
  @media (min-width: 768px) {
    &.subnav-wrapper {
      a:not(.button) {
        padding: 0 8px;
        &.active {
          &:before {
            left: 8px;
            bottom: -6px;
            width: calc(100% - 17px);
          }
        }
      }
      .subnavbar {
        padding-left: 16px;
        height: 22px;
        border-bottom: none;
      }
    }
  }
  @media (min-width: 1800px) {
    max-width: 1680px;
  }
`;

const SubNavBarTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-shrink: 2;
  width: 100%;
  justify-content: space-between;
  .link-button {
    margin-top: -15px;
  }
`;

const SubNavBar = ({ tabs, push, currentPathName, layout, links }) => {
  return (
    <Layout className={`${layout === "vertical" ? "sidebar" : ""}`}>
      {layout === "vertical" ? (
        <aside>
          {tabs.length > 0 && (
            <SubNavBarLayout className="tab-layout-vertical">
              <SubNavBarTabWrapper className="subnavbar">
                <Tabs
                  tabs={tabs}
                  currentPathName={currentPathName}
                  push={push}
                  links={links}
                  className="tabs"
                />
              </SubNavBarTabWrapper>
            </SubNavBarLayout>
          )}
        </aside>
      ) : (
        <div>
          {tabs.length > 0 && (
            <SubNavBarLayout className="subnav-wrapper">
              <SubNavBarTabWrapper className="subnavbar">
                <Tabs
                  tabs={tabs}
                  currentPathName={currentPathName}
                  push={push}
                  links={links ? links : {}}
                />
              </SubNavBarTabWrapper>
            </SubNavBarLayout>
          )}
        </div>
      )}
    </Layout>
  );
};

SubNavBar.propTypes = {
  tabs: PropTypes.array,
  push: PropTypes.func.isRequired,
  currentPathName: PropTypes.string.isRequired,
  layout: PropTypes.string,
  links: PropTypes.object
};

export default SubNavBar;
