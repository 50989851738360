import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router";
import ButtonLink from "Components/ButtonLink";

const TabsLayout = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 96vw;
  overflow: visible;
`;

const Tab = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 80%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.6px;
  a:not(.button) {
    text-decoration: none;
    color: #4a495e;
    &.active {
      color: #0e0d34;
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

const Tabs = ({ tabs, push, currentPathName, links, intl }) => (
  <TabsLayout className="tabs" role="menubar">
    {tabs.map(tab => {
      if (tab.permission && links && !links[tab.permission]) {
        return;
      }
      return (
        <Tab
          key={tab.name}
          active={
            (tab.regex && currentPathName.includes(tab.route)) ||
            currentPathName === tab.route
          }
          className={`tab-wrapper${tab.position === "right" ? " right" : ""}`}
        >
          {tab.type === "button" ? (
            <ButtonLink
              id={tab.name}
              className={`tab tab-${tab.name} ${
                (tab.regex && currentPathName.includes(tab.route)) ||
                currentPathName === tab.route
                  ? "active"
                  : ""
              }`}
              tabIndex="0"
              to={tab.route}
              external={true}
              blank={false}
              role="menuitem"
              aria-label={tab.name}
              text={intl.formatMessage({ id: `tabs.${tab.name}` })}
            />
          ) : (
            <Link
              id={tab.name}
              onClick={e => {
                e.preventDefault();
                push(tab.route);
              }}
              className={`tab tab-${tab.name} ${
                (tab.regex && currentPathName.includes(tab.route)) ||
                currentPathName === tab.route
                  ? "active"
                  : ""
              }`}
              tabIndex="0"
              href={tab.route}
              role="menuitem"
              aria-label={tab.name}
            >
              <FormattedMessage id={`tabs.${tab.name}`} />
            </Link>
          )}
        </Tab>
      );
    })}
  </TabsLayout>
);

Tabs.propTypes = {
  tabs: PropTypes.array,
  push: PropTypes.func.isRequired,
  currentPathName: PropTypes.string.isRequired,
  links: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Tabs);
